import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { UnsubscribeEmailFormComponent } from './unsubscribe-email-form-component'

export const UnsubscribeEmailForm = (props) => (
  <StaticQuery
    query={unsubscribeQuery}
    render={data =>
      <UnsubscribeEmailFormComponent
        apiUrl={data.site.siteMetadata.awsApiGatewayUrl}
        {...props} 
      />
    }
  />
)

const unsubscribeQuery = graphql`
  query unsubscribeQuery {
    site {
      siteMetadata {
        awsApiGatewayUrl
      }
    }
  }
`;