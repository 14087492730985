
export const makeUnsubscribeEmailRequest = ({ email, apiUrl }) => {
  const EMAIL_ENDPOINT = `${apiUrl}/subscribe`;

  return fetch(EMAIL_ENDPOINT, {
    method: 'DELETE',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
}