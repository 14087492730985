import React from 'react'
import PropTypes from 'prop-types'

import { useUnsubscribeEmailFormHook } from './use-unsubscribe-email-form-hook'
import { ROUTES } from '../../../constants/routes'
import * as S from './unsubscribe-email-form-styled'

const UnsubscribeEmailFormComponent = ({ email, apiUrl }) => {
  const {
    isLoading,
    showSuccessMessage,
    emailInputText,
    handleEmailInputChange,
    errorMessage,
    handleUnsubscribe,
  } = useUnsubscribeEmailFormHook({ email, apiUrl });

  return (
    <S.Container>

      {showSuccessMessage ? (
        <>
          <S.LargeText>Sorry to see you go</S.LargeText>

          <S.Text>You've been successfully unsubscribed.</S.Text>

          <S.HomeButton onClick={() => window.location.href = ROUTES.home.path}>
            Back to Home
          </S.HomeButton>
        </>
      ) : (
        <>
          <S.LargeText>Unsubscribe</S.LargeText>

          <S.Text>
            If you no longer wish to receive emails, please enter your e-mail below and click unsubscribe.
          </S.Text>

          <S.EmailInput
            value={emailInputText}
            placeholder="e-mail address"
            onChange={e => handleEmailInputChange(e.target.value)}
          />

          {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}

          {isLoading ? (
            <S.LoadingIcon />
          ) : (
            <S.UnsubscribeButton
              onClick={handleUnsubscribe}
              disabled={isLoading}
            >
              Unsubscribe
            </S.UnsubscribeButton>
          )}
        </>
      )}

    </S.Container>
  )
}

UnsubscribeEmailFormComponent.propTypes = {
  email: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
}

export { UnsubscribeEmailFormComponent }