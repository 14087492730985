import { useState, useEffect } from 'react'

import { isValidEmail } from '../../../helpers/email/email'
import { makeUnsubscribeEmailRequest } from './unsubscribe-email-form-networking'

export const useUnsubscribeEmailFormHook = ({ email, apiUrl }) => {
  const [emailInputText, setEmailInputText] = useState(email);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEmailInputText(email);
  }, [email])

  const handleEmailInputChange = (text) => {
    setEmailInputText(text);
    setErrorMessage('');
  }

  const handleUnsubscribe = () => {

    if (emailInputText && isValidEmail(emailInputText) && !isLoading) {

      setIsLoading(true);

      makeUnsubscribeEmailRequest({ email: emailInputText, apiUrl })
        .then(response =>  response.json())
        .then(response => {
          if (response.error) {
            throw new Error(response.error);
          } else {
            setIsLoading(false);
            setEmailInputText('');
            setShowSuccessMessage(true);
          }
        })
        .catch(err => {
          console.error(err);
          setIsLoading(false);
          setErrorMessage(`An error occurred. ${err.message}`);
        })

    } else {

      setErrorMessage('Invalid e-mail.');

    }
  }

  return {
    isLoading,

    showSuccessMessage,

    emailInputText,
    handleEmailInputChange,

    errorMessage,

    handleUnsubscribe,
  }
}