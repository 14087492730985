import React, { useState, useEffect } from 'react'

import Layout from '../components/common/layout/layout-component'
import SEO from '../components/common/seo'
import { UnsubscribeEmailForm } from '../components/pages/unsubscribe-email-form/unsubscribe-email-form-container'

const UnsubscribePage = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get('email'));
  }, [])

  return (
    <Layout>

      <SEO
        title="Unsubscribe"
        keyword={['unsubscribe', 'email', 'cooking', 'travel', 'travelling']}
      />

      <UnsubscribeEmailForm email={email} />

    </Layout>
  )
}

export default UnsubscribePage;