import styled from 'styled-components'

import { MainTitle, BodyText } from '../../common/text-styled'
import { TextInput } from '../../common/inputs/text-input-styled'
import { Button } from '../../common/inputs/button-styled'
import { LoadingSpinner } from '../../common/loading-spinner/loading-spinner-component'
import { media } from '../../../constants/media'

export const Container = styled.div`
  margin: 0 40px;
  @media ${media.medium.query} {
    margin: 0;
  }
`;

export const LargeText = styled(MainTitle)`
  margin-bottom: 20px;
  text-align: center;
  @media ${media.medium.query} {
    margin-bottom: 25px;
    text-align: left;
  }
`;

export const Text = styled(BodyText)`
  margin-bottom: 40px;
  text-align: center;
  @media ${media.medium.query} {
    margin-bottom: 50px;
    text-align: left;
  }
`;

export const ErrorMessage = styled(BodyText)`
  color: ${props => props.theme.concepts.errorColor};
`;

export const EmailInput = styled(TextInput)`
  margin-bottom: 25px;
  @media ${media.medium.query} {
    margin-bottom: 40px;
  }
`;

export const HomeButton = styled(Button)`
`;

export const UnsubscribeButton = styled(Button)`
`;

export const LoadingIcon = styled(LoadingSpinner)`
`;